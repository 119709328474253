<template>
  <div v-if="widget" class="page-header">
    <div>
      <h3>Embed</h3>
      <h6><strong>{{ widget.details.name }}</strong></h6>
    </div>
    <router-link :to="{ name: 'Overview', params: { id: id } }" class="btn">Back</router-link>
  </div>
  <div v-if="widget" class="container">
    <div class="navigation">
      <ul class="tab-list" role="tablist">
        <li class="tab-item" :class="{ selected: embed == 'web' }" role="tab" @click="handleClick('web')"><i class="fas fa-code"></i> Website</li>
        <li class="tab-item" :class="{ selected: embed == 'wordpress' }" role="tab" @click="handleClick('wordpress')"><i class="fab fa-wordpress"></i> Wordpress</li>
        <li class="tab-item" :class="{ selected: embed == 'shopify' }" role="tab" @click="handleClick('shopify')"><i class="fab fa-shopify"></i> Shopify</li>
        <!-- <li class="tab-item" :class="{ selected: embed == 'medium' }" role="tab" @click="handleClick('medium')"><i class="fab fa-medium"></i> Medium</li> -->
        <li class="tab-item" :class="{ selected: embed == 'squarespace' }" role="tab" @click="handleClick('squarespace')"><i class="fab fa-squarespace"></i> Squarespace</li>
        <li class="tab-item" :class="{ selected: embed == 'email' }" role="tab" @click="handleClick('email')"><i class="fas fa-envelope"></i> Email</li>
      </ul>
    </div>
    <div class="inner-container">
      <div class="instruction-list">
      <div class="instruction-step one">
      <h4>Copy the code</h4>
      <p v-if="embed != 'wordpress'">Here's your unique code snippet. Click the button below to copy it right in your clipboard.</p>
      <p v-else>Here's your unique Widget ID. Click the button below to copy it right in your clipboard.</p>
      <div class="textarea">
            <textarea readonly v-if="embed != 'wordpress' && embed != 'medium' && embed != 'email'"><!-- Emojise Widget Start -->
<script>
  (function(d, w, c) {
    function l() {
      w.emojise_widget = { widget: '{{ id }}' };
      var s = d.createElement('script');
      s.async = true;
      s.id = c;
      s.src = 'https://app.emojise.com/embed/widget.js';
      d.getElementsByTagName('head')[0].appendChild(s);
    };
    w.onload = l();    
  })(document, window, 'emojise-js');
</script>
<!-- Emojise Widget End --></textarea>
            <textarea readonly v-if="embed == 'wordpress'" class="small">{{ id }}</textarea>
            <textarea readonly v-if="embed == 'medium'" class="small">https://app.emojise.com/api/oembed?widget={{ id }}</textarea>
            <div v-if="embed == 'email'" class="email-details">
              <select v-model="emailAlign" class="email-select">
                <option value="center">Align Center</option>
                <option value="left">Align Left</option>
              </select>
              <i class="fas fa-info-circle" title="For text-only emails (sales, support, personal) we recommend the left-hand position, while for graphic emails (newsletter) we recommend centered position." tabindex="0"></i>
            </div>
            <textarea readonly v-if="embed == 'email' && widget.settings.branding"><div id="emojise-email-widget" style="text-align: {{ emailAlign }};"><div class="emojise-content"><h1 class="title" style="color: #4f515a; line-height: 1; font-size: 16px; font-weight: 600; font-family: Arial, sans-serif;">{{ widget.texts.title }}</h1><p class="subtitle" style="color: #4f515a; line-height: 1; font-size: 14px; line-height: initial; font-family: Arial, sans-serif;">{{ widget.texts.subtitle }}</p></div><div class="emojise-reactions" style="margin-bottom: 10px;">{{ emojis }}</div><p class="branding" style="margin-left: 170px; color: #696969; font-size: 12px; line-height: 1; font-family: Arial, sans-serif;"><a href="https://emojise.com/" target="_blank" style="color: inherit; text-decoration: none;">⚡ by Emojise</a></p></div></textarea>
            <textarea readonly v-else-if="embed == 'email' && !widget.settings.branding"><div id="emojise-email-widget" style="text-align: {{ emailAlign }};"><div class="emojise-content"><h1 class="title" style="color: #4f515a; line-height: 1; font-size: 16px; font-weight: 600; font-family: Arial, sans-serif;">{{ widget.texts.title }}</h1><p class="subtitle" style="color: #4f515a; line-height: 1; font-size: 14px; line-height: initial; font-family: Arial, sans-serif;">{{ widget.texts.subtitle }}</p></div><div class="emojise-reactions" style="margin-bottom: 10px;">{{ emojis }}</div></div></textarea>
          </div>
          <button @click="handleCopy">Copy Code</button>
          <button v-if="embed == 'email'" @click="handleEmail" class="secondary" style="margin-left: 10px;">Run Code</button>
        </div>
        <div class="instruction-step two">
          <h4>Paste the code</h4>
          <p v-if="embed == 'web'">Paste the code directly above the closing &lt;/head&gt; tag on every page you want the widget to appear.<br>We recommend adding the code to your website template (or the file serving the main portion of your HTML), so that it automatically goes into each page of your site.</p>
          <p v-if="embed == 'wordpress'">Log in to your WordPress Admin panel and head to Plugins → Add New.<br>Search for and install the <a href="https://wordpress.org/plugins/emojise/"><u>Emojise</u></a> plugin.<br>Next, activate the plugin and visit Settings → Emojise.<br>Paste your Widget ID in the field and hit 'Save'.</p>
          <p v-if="embed == 'shopify'">Log in to your store and click on Online Store → Themes → Actions → Edit Code.<br>Click on theme.liquid in the Layout folder and paste the code directly above the closing &lt;/head&gt; tag.</p>
          <p v-if="embed == 'medium'">Paste the copied link in your Medium story where you want the widget to appear.</p>
          <p v-if="embed == 'squarespace'">Log in to your Squarespace account and head to your website editor.<br>Next, navigate to Settings → Advanced → Code Injection.<br>Paste your embed code in the top box ('Header') and click 'Save'.<br><br>Note: This is a 'Premium Feature' on Squarespace.</p>
          <p v-if="embed == 'email'">Paste the embed code into your email template, using your email provider's HTML editor.<br>Alternatively, click 'Run HTML Code', copy the widget and paste it into your email. Your email signature is the recommended area.<br><br>Note: If you make changes to your campaign, you'll have to repeat these steps.</p>
        </div>
        <div v-if="widget.settings.embed == 'inline' && embed != 'medium' && embed != 'email'" class="instruction-step three">
          <h4>Add additional code</h4>
          <p v-if="embed == 'web'">Paste the following additional code wherever you want the inline widget to appear on your page.<br>Include the snippet on each page you'd like your widget to be shown.</p>
          <p v-if="embed == 'wordpress'">To specify where your inline widget should appear, copy and paste the below snippet anywhere in your content - on every page/post where you need your widget.</p>
          <p v-if="embed == 'shopify'">Copy and paste the below code wherever you want the widget to appear on your page. For example, it could be somewhere in 'product.liquid'.</p>
          <p v-if="embed == 'squarespace'">Navigate to each page you want the widget to appear on and click 'Edit'.<br>Next, click the <a href="https://support.squarespace.com/hc/en-us/articles/206543757#toc-insert-points"><u>Insert point</u></a> where you want the widget to show and select 'Code' from the list. Simply paste the below code inside and hit 'Save'.</p>
          <div class="textarea">
            <textarea v-if="embed != 'wordpress'" class="small" readonly><div id="emojise-widget-inline-embed"></div></textarea>
            <textarea v-else class="small" readonly>[emojise-inline]</textarea>
          </div>
          <button @click="handleCopy">Copy Code</button>
        </div>
        <div class="instruction-step done">
          <h4>Done!</h4>
          <p>It should be that easy, but if something doesn't work or you need more help, don't hesitate to contact us.</p>
        </div>
      </div>
    </div>
  </div>
  <div v-else class="inner-container not-found">
    <h2>Oops... Widget Not Found</h2>
  </div>
</template>

<script>
import { ref, computed } from 'vue'
import getCollection from '../composables/getCollection'

export default {
  props: ['id'],
  setup(props) {
    const { widgets } = getCollection()
    const embed = ref('web')
    const emailAlign = ref('center')
    const code = ref(null)

    const widget = computed(() => {
      if (widgets.value) {
        return widgets.value.find(el => el.id == props.id)
      }
    })

    const emojis = computed(() => {
      if (widget.value) {
        let string = ''

        Object.entries(widget.value.emojis).forEach(([key, value]) => {
          string += `<a href="https://app.emojise.com/embed/thanks.html?widget=${props.id}&bg=${widget.value.settings.bgColour}&vote=${value.value}" target="_blank"><img src="${value.emojiURL}" style="width: 35px !important; height: 35px !important; cursor: pointer; margin: 5px;"></a>`
        })

        return string
      }
    })

    const handleClick = (type) => {
      embed.value = type
    }

    const handleEmail = e => {
      code.value = e.target.parentNode.querySelector('textarea').value;
      const newWindow = window.open('', 'Emojise Email Widget', 'width=400,height=200');
      newWindow.document.write(code.value);
    }

    const handleCopy = e => {
      e.target.parentNode.querySelector('textarea').select();
      document.execCommand('copy');
      e.target.disabled = true;
      e.target.innerHTML = 'Copied! 🚀';

      setTimeout(() => {
        e.target.innerHTML = 'Copy Code';
        e.target.disabled = false;
      }, 2000);
    }

    return { widget, embed, handleClick, emojis, emailAlign, handleCopy, handleEmail }
  }
}
</script>

<style>
  .navigation {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  ul:not(.browser-default) {
    padding-left: 0;
    list-style-type: none;
  }
  .tab-list {
    margin: 0 0 10px 0;
    overflow-y: hidden;
  }
  .tab-item {
    display: inline-block;
    border: 1px solid;
    position: relative;
    padding: 10px 20px;
    padding-top: 10px;
    margin: 0 10px 10px 0;
    cursor: pointer;
    background-color: #fff;
    border-radius: 3px;
    -webkit-box-shadow: 0 0 0 1px #e6eaed;
    box-shadow: 0 0 0 1px #e6eaed;
  }
  .tab-item.selected {
    z-index: 10;
    font-weight: 600;
    color: var(--main);
  }
  .tab-item.selected i {
    color: var(--main);
  }
  .instruction-list {
    counter-reset: step;
    margin: 30px auto 15px;
    padding-left: 20px;
    max-width: 85%;
  }
  .instruction-step {
    display: block;
    position: relative;
    padding: 0 0 40px 20px;
  }
  .instruction-step::before {
    counter-increment: step;
    content: counters(step,".") " ";
    position: absolute;
    left: -35px;
    height: 40px;
    width: 40px;
    font-size: 18px;
    font-weight: 600;
    line-height: 36px;
    text-align: center;
    color: #ffa1a1;
    background-color: #ffffff;
    border: 2px solid #ffa1a1;
    border-radius: 50%;
  }
  .instruction-step:last-child::before {
    content: '\2713';
  }
  .instruction-step:last-child {
    padding: 0 0 20px 20px;
  }
  .instruction-step textarea {
    display: inline-block;
    position: relative;
    width: 100%;
    height: 120px;
    margin: 10px auto;
    padding: 10px;
    line-height: 22px;
    border-radius: 6px;
    border: none;
    resize: none;
    background-color: #fff0f0;
    overflow: auto;
  }
  .instruction-step textarea.small {
    height: 50px;
    font-weight: 600;
  }
  .email-details {
    text-align: center;
    margin-top: 15px;
  }
  .email-select {
    height: 27px;
    margin-right: 10px;
    border: 1px solid var(--main);
    border-radius: 7px;
    padding: 0 5px;
    background-color: #fff0f0;
    cursor: pointer;
  }
</style>